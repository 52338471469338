/*control bar height*/
.video-js .vjs-control-bar {
  height: 4em !important;
  background-color: rgba(51, 51, 51, .7);
}

/* play button */
.video-js.vjs-big-play-centered .vjs-big-play-button {
  height: 3em;
  width: 3em;
  line-height: 3em;
  margin-top: -1.5em;
  border-radius: 1.5em;
  background-color: rgba(51, 125, 194, 0.5);

  transition: background-color;
  transition-duration: 200ms;

}

.video-js.vjs-big-play-centered .vjs-big-play-button:hover {
  background-color: rgba(51, 125, 194, 0.75);
}

.video-js.vjs-big-play-centered .vjs-big-play-button .vjs-icon-placeholder {
  font-size: 2em;
}

/*spinner*/
.video-js .vjs-loading-spinner {
  border: 6px solid rgba(43, 43, 43, .7);
}

/*buttons*/
.video-js .vjs-button > .vjs-icon-placeholder::before {
  font-size: 2.3em;
  line-height: 1.7em;
}

.vjs-button.vjs-max-quality-selector-button > button.vjs-menu-button-popup > .vjs-icon-placeholder {
  font-size: 1.25em;
}

.vjs-airplay-button .vjs-icon-placeholder {
  width: 2em;
  height: 4em;
}

.vjs-chromecast-button .vjs-icon-placeholder {
  width: 2em;
  height: 4em;
}

/*time*/
.video-js .vjs-time-control {
  font-size: 1.7em;
  line-height: 2.2em;
}

.video-js .vjs-volume-bar {
  margin: 1.9em .45em;
}

.video-js .vjs-time-tooltip {
  padding: 6px 8px;
}

.video-js .vjs-progress-control:hover .vjs-progress-holder:focus .vjs-time-tooltip, .video-js .vjs-progress-control:hover .vjs-time-tooltip {
  display: block;
  font-size: 0.8em;
  visibility: visible;
}



/*volume*/
.vjs-slider-horizontal .vjs-volume-level::before {
  top: -.45em;
  right: -.5em;
}

.video-js .vjs-volume-level::before {
  position: absolute;
  font-size: 2.2em;
}

/* pop up menus */
.js-focus-visible .vjs-menu li.vjs-menu-item:hover, .vjs-menu li.vjs-menu-item:focus, .vjs-menu li.vjs-menu-item:hover {
  background-color: rgba(115, 115, 115, .5);
}

.video-js .vjs-menu li {
  text-transform: capitalize;
}

.vjs-menu-button-popup .vjs-menu {
  margin-bottom: 2.0em;
  border-top-color: rgba(43, 43, 43, .7);
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  background-color: rgba(43, 43, 43, .7);
}

.js-focus-visible .vjs-menu li.vjs-selected:hover, .vjs-menu li.vjs-selected, .vjs-menu li.vjs-selected:focus, .vjs-menu li.vjs-selected:hover {
  background-color: rgba(115, 115, 115, .7);
  color: white;
}

/*progress bar*/
.video-js .vjs-play-progress::before {
  font-size: 1.5em;
  top: -.4em;
}

.video-js .vjs-load-progress {
  background: rgba(115, 115, 115, .5);
}

.video-js .vjs-load-progress div {
  background: rgba(133, 133, 133, .75);
}

.video-js .vjs-slider {
  background-color: rgba(159, 159, 159, .5);
}

.video-js .vjs-playback-rate .vjs-playback-rate-value {
  line-height: 2.5;

}


