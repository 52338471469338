@import "variables";

.list-group {
  width: 100%;
  margin: rem(4) 0px 0px 0px;

  .list-group-item {
    background-color: inherit !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0px !important;
    margin: 0px !important;
    height: 48px;
    border-bottom: 1px solid $lightgray;
    border-top: none !important; //1px solid $inactive_gray;

    &:first-child {
      //border-top: none !important;
    }

    &:last-child {
      border-bottom: none !important;
    }

    a {
      width: 100%;
      @include Course_Text_Md;

      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;

      color: $type_gray;

      > span {
        width: #{calcrem(100%, 10)};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
