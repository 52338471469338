@import "variables";

button {
  outline: none !important;
}

.btn-group {
  .btn {
    padding: rem(1) rem(3);
  }
}

.btn, a.btn {
  padding: rem(1) rem(0);
  //margin: rem(0) rem(3);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  overflow: hidden;
  white-space: nowrap;

  //font-size: rem(4) !important;
  //line-height: rem(6) !important;
  @include Course_Text_Md_Bold;

  color: $primary_blue !important;

  &.btn-round {
    padding: rem(1) rem(4);
    border-radius: 22px;
  }

  &.btn-primary, &.btn-warning, &.btn-danger, &.btn-info {
    color: white !important;
  }

  &.btn-warning, &.btn-danger, &.btn-info, &.btn-success {
    color: white !important;
    padding: rem(2) rem(3);
  }

  &.btn-shadow {
    box-shadow: 0px 1px rem(3) rgba(0, 0, 0, 0.22);
  }

  &.btn-outline-primary:hover {
    color: white !important;
  }

  &.btn-border-red {
    color: black !important;
    background-color: white !important;
    border-color: red !important;
  }

  &.btn-border-gray {
    color: $black !important;
    background-color: $white !important;
    border-color: $type_gray_dark_mode !important;
  }

  &.btn-white {
    text-align: center;
    color: $primary_blue !important;
    background-color: white;
    box-shadow: 0px 1px rem(3) rgba(0, 0, 0, 0.22);
    border-radius: 30px;

    &:hover {
      color: $primary_blue !important;
      background-color: rgba(256, 256, 256, 0.75);
    }

    &.btn-blue-border {
      border: 1px solid $primary_blue;
    }


  }

  &.btn-disabled.btn-round {
    box-shadow: none !important;
    background-color: $inactive_gray !important;
    color: white !important;
  }

  &.btn-disabled {
    box-shadow: none !important;
    color: $inactive_gray !important;
    background-color: inherit !important;
  }

  &.btn-lg {
    padding: rem(2) rem(6);
  }

  &.btn-w-100 {
    width: 100% !important;
  }

  &.btn-w-75 {
    width: 75% !important;
  }

  &.btn-width-fixed-140 {
    width: 140px !important;
  }

  &.btn-width-fixed-240 {
    width: 240px !important;
  }

  &.btn-no-padding {
    padding: 0px !important;
    margin: 0px !important;
  }

  &.btn-plane {
    background-position: right 20px top 0px;
    background-repeat: no-repeat;
    background-size: 30px;
    position: relative;

    &.btn-white {
      background-image: url("https://dl.videos.sportys.com/onlinecourse/images/plane@3x.png");
    }

    &.btn-primary {
      background-image: url("https://dl.videos.sportys.com/onlinecourse/images/planewhite@3x.png");
    }

    &.btn-loading {
      background-image: none !important;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }

    &.btn-loading::before {
      position: absolute;
      right: 20px;
      height: 26px;
      width: 26px;
      line-height: 26px;
      font-size: 26px;
      font-family: 'FontAwesome';
      content: "\f1ce";

      animation: spin 1.5s infinite linear;
      -webkit-animation: spin 1.5s infinite linear;

    }

  }

  &.btn-working {
    background-position: right 20px top 0px;
    background-repeat: no-repeat;
    background-size: 30px;
    position: relative;

    &.btn-loading {
      background-image: none !important;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }

    &.btn-loading::before {
      position: absolute;
      right: 5px;
      height: 26px;
      width: 26px;
      line-height: 26px;
      font-size: 26px;
      font-family: 'FontAwesome';
      content: "\f1ce";

      animation: spin 1.5s infinite linear;
      -webkit-animation: spin 1.5s infinite linear;

    }

  }
}


.btn-purchase {
  display: inline-flex !important;
  text-align: center;
  color: $type_gray !important;
  background-color: $purchase;

  > div {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  &:hover {
    color: $type_gray !important;
    background-color: lighten($purchase, 0.5) !important;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.22);
  }

  &.btn-lg {
    width: auto !important;
    min-width: auto !important;
    max-width: inherit !important;
    border-radius: rem(2);
    text-align: left;
    white-space: inherit !important;


    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    margin: 0px;
    padding: 10px 5px 10px 5px;
    height: auto !important;

    > div:first-child {

    }

    > div:last-child {
      margin: 0px;
      white-space: normal;
      width: calc(100% - 34px);
    }
  }
}

// &.btn-locked:active {
//   outline-style: solid !important;
//   outline-color: #ffc107 !important;
// }

.btn-locked:focus, .btn-locked.focus {
  outline: 0;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 0 0 0.2rem #ffc107 !important;
  box-shadow: 0 0 0 0.2rem #ffc107 !important;
}

.btn-error {

  background-color: $red !important;
  color: $white !important;

}

.btn-purchase-new {
  display: inline-flex !important;
  text-align: center;
  color: $type_gray !important;
  background-color: $lock_orange;

  > div {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  &:hover {
    color: $type_gray !important;
    background-color: lighten($purchase, 0.5) !important;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.22);
  }

  &.btn-lg {
    width: auto !important;
    min-width: auto !important;
    max-width: inherit !important;
    border-radius: rem(2);
    text-align: left;
    white-space: inherit !important;


    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    margin: 0px;
    padding: 10px 5px 10px 5px;
    height: auto !important;

    > div:first-child {

    }

    > div:last-child {
      margin: 0px;
      white-space: normal;
      width: calc(100% - 34px);
    }
  }
}
