/* Text styles */
// @import "assets/styles/variables";
@import "assets/styles/variables";
@import "assets/styles/mixins";
@import "node_modules/bootstrap/scss/bootstrap";
@import "sprite";
@import "assets/styles/backgrounds";
@import "assets/styles/buttons";
@import "assets/styles/cards";
@import "assets/styles/forms";
@import "assets/styles/modal";
@import "assets/styles/plates";
@import "assets/styles/lists";
/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';

//@import 'node_modules/video.js/src/css/video-js';

/*
@import 'node_modules/videojs-vtt-thumbnails/src/plugin.scss';
*/
html, body {
  font-family: Lato;
  margin: 0px auto;
}

html {
  height: 100%;
}

body {
  // height: calc(100vh - $navbar-height);
  // height: 100vh !important;
  font-size: 16px;
  line-height: rem(6);
}


h1 {
  @include Course_H1;
  margin: 0px;
  padding: rem(1) rem(0);

  > i.fa {
    font-weight: 100;
  }
}

h2 {
  @include Course_H2;
  margin: 0px;
  padding: rem(1) rem(0);
  line-height: rem(6);

}

h3 {
  @include Course_H3;
  margin: 0px;
  padding: rem(1) rem(0);
  line-height: rem(5);

  &.dark {
    @include Course_H3_Dark;
  }
}

h4 {
  @include Course_H4;
  margin: 0px;
  padding: rem(1) rem(0);

  &.dark {
    @include Course_H4_Dark;
  }
}


h5 {
  @include Course_H5;
  margin: 0px;
  padding: rem(1) rem(0);
}

p {
  font-size: rem(3.5);
  line-height: rem(6);
  margin: 0px;
  padding: rem(0) rem(0) rem(0) rem(0);

  &.caption {
    font-size: rem(3);
    line-height: rem(4);
  }

  &.muted {
    color: $type_gray;
  }
}


a {
  cursor: pointer !important;

  :hover {
    cursor: pointer !important;
  }
}

.help-center-content {
  h1, h2 {
    font-family: $main-font;
    font-weight: 500;
  }
}

.notes-container {
  h1, h2 {
    font-family: $main-font;
    font-weight: 500;
  }
}

.sportys-icon, .sportys-badge {
  //background-color: red;
  min-height: 10px;
  min-width: 10px;
}


span.inactive {
  color: $inactive_gray;
}

.responsive-content {
  /*border: 1px solid red;*/
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;
  padding: rem(10) rem(12);

  .responsive-content-body {
    margin: 0 auto;

    .test_prep_plate.plate.checkride_plate {
      background-color: $lightgray_dark_mode !important;
    }
  }

  .responsive-content-body, .responsive-content-footer, .responsive-content-header {
    max-width: 1045px;
    width: 100%;


    &.tight {
      max-width: 1000px;
    }

    &.wide {
      max-width: 100%;
    }
  }

  .responsive-content-header {
    display: flex;
  }

  .responsive-content-footer {
    display: flex;
    justify-content: center;
  }

  .responsive-content-mobile {

    display: flex;
    flex-direction: column;

    > div {
      margin: 5px 0px;
    }

    button, a {
      width: 100% !important;
    }
  }

  @media (min-width: $phone_lg) {
    .responsive-content-body {

    }

    .responsive-content-desktop {

    }
    .responsive-content-mobile {
      display: none;
    }

    .responsive-content-footer {
      padding: 0px rem(6) 0px rem(4);
    }
  }

  @media (max-width: $phone_lg) {
    .responsive-content-body {
      //padding: 0px 8px 0px 8px !important;

    }
    .responsive-content-desktop {
      display: none;
    }
    .responsive-content-mobile {

    }
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  height: $navbar-logo-height;
}

.navbar-dark {
  .navbar-toggler {
    border: none;

    .navbar-toggler-icon {
      background-image: url('https://dl.videos.sportys.com/onlinecourse/images/menu-open@3x.png');
    }
  }

  .navbar-toggler_open {
    .navbar-toggler-icon {
      background-image: url('https://dl.videos.sportys.com/onlinecourse/images/menu-close@3x.png');
    }
  }
}

.area {
  padding: rem(3) rem(0);
}

.custom-popover {
  background-color: $popover-gray;
  border: 1px solid $popover-gray;
  background-clip: border-box;

  .popover-body {
    color: white !important;
  }

  &.bs-popover-top {
    .popover-arrow {

      &::before {
        border-top-color: $popover-gray;
      }

      &::after {
        border-top-color: $popover-gray;
      }
    }
  }

}


div[app-custom-radio] {
  display: inline;
  position: absolute;
}

div.alert-in-progress {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background-color: $callout;
  border-radius: 4px;

  line-height: 21px;
  height: 24px;
  width: 90px;
  text-transform: uppercase;

  @include Course_Text_Xs;
}

@media (max-width: $desktop_xl) {
  .responsive-content {
    padding: rem(3) !important;
  }
}

@media (max-width: $phone_lg) {
  body {
    overflow-x: hidden;
  }

  .responsive-content {
    padding: rem(3) !important;
  }

  .modal {
    .modal-dialog {
      &.modal-lg {
        min-width: calc(100% - 20px);
        max-width: calc(100% - 20px)
      }

      &.modal-ratio {
        min-width: calc(100% - 10px);
        max-width: calc(100% - 10px)
      }

      &.modal-xlg {
        min-width: calc(100% - 10px);
        max-width: calc(100% - 10px)
      }
    }
  }


  .card-container {
    width: 100%;
    padding: 0px 0px 0px 0px;

    > div {
      width: 100%;
      margin: rem(0) rem(0) rem(5) rem(0);

      .card-skinny {
        width: 100%;
      }
    }
  }

}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.text-truncate-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-truncate-3 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-truncate-4 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-color-gray {
  color: $type_gray;
}

.primary_blue {
  color: $primary_blue
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.background-transparent {
  background: transparent !important;
  color: #000000;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.text-size-10 {
  font-size: rem(2.5) !important;
  line-height: rem(2.5) !important;
}

.text-size-12 {
  font-size: rem(3) !important;
  line-height: rem(4) !important;
}

.text-size-13 {
  font-size: rem(3.25) !important;
  line-height: rem(3.25) !important;
}

.text-size-14 {
  font-size: rem(3.5) !important;
  line-height: rem(3.5) !important;
}

.text-size-16 {
  font-size: rem(4) !important;
  line-height: rem(4) !important;
}

.text-size-18 {
  font-size: rem(4.5) !important;
  line-height: rem(4.5) !important;
}

.text-size-20 {
  font-size: rem(5) !important;
  line-height: rem(5) !important;
}

.text-size-22 {
  font-size: rem(5.5) !important;
  line-height: rem(5.5) !important;
}

.text-size-24 {
  font-size: rem(6) !important;
  line-height: rem(6) !important;
}

.text-size-26 {
  font-size: rem(6.5) !important;
  line-height: rem(6.5) !important;
}

.text-size-28 {
  font-size: rem(7) !important;
  line-height: rem(7) !important;
}

.text-size-30 {
  font-size: rem(7.5) !important;
  line-height: rem(7.5) !important;
}

.text-size-32 {
  font-size: rem(8) !important;
  line-height: rem(8) !important;
}

.text-size-38 {
  font-size: rem(10) !important;
  line-height: rem(10) !important;
}

.border-left-thin-gray {
  border-left: 1px solid #C0C0C0;
}

.border-right-thin-gray {
  border-right: 1px solid #C0C0C0;
}

@media (min-width: $display_lg) {
  .border-lg-right-thin-gray {
    border-right: 1px solid #C0C0C0;
  }
}

@media (min-width: $display_md) {
  .text-size-md-14 {
    font-size: rem(3.5) !important;
  }
}

@media (max-width: $display_sm) {
  .text-size-sm-12 {
    font-size: rem(3) !important;
  }
}

a.no-decorations {
  text-decoration: none;
  color: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

.tagline {
  color: $white;
  border-left: 1px solid $white;
  padding-left: 10px;
  line-height: 1;
}

.bg-cfi {
  background-color: $certification_blue;

  .nav-item a {
    color: $white;
  }

  p {
    a {
      color: #0074cc !important;
    }
  }
}

.multiselect-dropdown {
  appearance: none !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  max-width: 300px !important;
  border: 1px solid #ced4da !important;

  :nth-child(2) {
    padding-right: 20px !important;
    cursor: pointer !important;
  }
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  border-width: 5px 5px 0px !important;
  border-color: #000 transparent !important;
}

.border-bottom-left-radius-0 {
  border-bottom-left-radius: 0px;
}

.border-bottom-right-radius-0 {
  border-bottom-right-radius: 0px;
}

.modal-dialog.modal-lg {
  .modal-content {
    max-width: 100vw !important;
  }
}

.modal-dialog.modal-xlg {
  width: calc(100vw - 40px);

  .modal-content {
    max-width: 100vw !important;
    min-width: 475px;
  }

  .modal-body {
    height: 55%;
    align-items: center;
    display: flex;
  }
}

.modal-dialog.modal-ratio {
  width: calc(100vw - 40px);
  max-width: 800px;
  height: calc(100vw * 9 / 16);
  max-height: calc(100vh - 50px);
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .modal-content {
    max-width: 100% !important;
    max-height: 500px;
    overflow: hidden;
    height: 100%;
  }

  .modal-body {
    align-items: center;
    display: flex;
  }
}

.modal-content {
  border-radius: 0.3rem !important;
  max-width: 60vw !important;
}

.border-top-left-radius-modal {
  border-top-left-radius: 1.56rem;
}

.border-top-right-radius-modal {
  border-top-right-radius: 1.56rem;
}

.border-bottom-left-radius-modal {
  border-top-left-radius: 1.56rem;
}

.border-bottom-right-radius-modal {
  border-top-right-radius: 1.56rem;
}

div.rounded-modal > div.modal-content {
  border-radius: 1.7rem !important;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white !important;
}

modal-container {
  background: rgba(1, 1, 1, 0.3) !important;
}

// DARK MODE

@function gen-palette($color) {
  @return (
    "50": lighten($color, 52%),
    "100": lighten($color, 37%),
    "200": lighten($color, 26%),
    "300": lighten($color, 12%),
    "400": lighten($color, 6%),
    "500": $color,
    "600": darken($color, 6%),
    "700": darken($color, 12%),
    "800": darken($color, 18%),
    "900": darken($color, 24%),
    "A100": lighten(saturate($color, 30%), 50%),
    "A200": lighten(saturate($color, 30%), 30%),
    "A400": lighten(saturate($color, 15%), 10%),
    "A700": lighten(saturate($color, 5%), 5%),
  );
}

//$inside-primary: gen-palette($primary_blue);
//$inside-accent: gen-palette($primary_blue);
//$inside-warn: gen-palette($red);
//$inside-theme-dark: mat-dark-theme($inside-primary, $inside-accent, $inside-warn);
//$inside-theme-light: mat-light-theme($inside-primary, $inside-accent, $inside-warn);

body {
  &.color-scheme-dark {
    color: $white !important;
    background-color: $gray_dark_mode !important;
  }
}

.color-scheme-dark {
  // custom style for dark mode
  app-students-filter {
    background-color: $lightgray_dark_mode;
    color: $white !important;
  }

  app-student-card {
    background-color: $lightgray_dark_mode;
    color: $white !important;
  }

  iframe {
    background-color: rgb(32, 43, 54) !important;
    color: $white !important;

    .notes {
      background-color: $lightgray_dark_mode !important;
      color: $white !important;
    }
  }

  #dropin-container {
    iframe {
      background-color: unset !important;
    }
  }

  .sidenav > .btn, a {
    color: $primary_blue_dark_mode !important;
  }

  .navbar.navbar-dark.bg-primary {
    background-color: $gray_dark_mode !important;
  }

  #navbarSupportedContent {
    .navbar-nav {
      .nav-item {
        .user-info-panel {
          .user-info-footer {
            .nav-link {
              color: $black !important;
            }
          }
        }
      }

      .nav-link {
        color: rgba(255, 255, 255, 0.5) !important;
      }

      .nav-link.navigation-link {
        color: rgba(255, 255, 255) !important;
      }

      .nav-link:hover {
        color: rgba(255, 255, 255, 0.8) !important;
      }

      .nav-link.navigation-link:hover {
        color: rgba(255, 255, 255) !important;
      }

      .nav-link.active {
        color: $white !important;
      }

      .nav-link.btn.btn-white {
        color: rgba(255, 255, 255, 0.8) !important;
        background-color: $primary_blue_dark_mode !important;
      }

      .nav-link.btn.btn-white:hover {
        color: $white !important;
        background-color: $primary_blue_dark_mode !important;
      }
    }
  }

  .bg-primary {
    background-color: $primary_blue_dark_mode !important;
    color: $white !important;
  }

  .primary_blue {
    color: $primary_blue_dark_mode !important;
  }

  .btn-white {
    color: $white !important;
    background-color: $lightgray_dark_mode !important;
  }

  .responsive-content {
    background-color: $gray_dark_mode !important;
    color: $white !important;
  }

  .body-content {
    background-color: #0c1823 !important;
  }

  .video-body.responsive-content {
    background-color: rgb(32, 43, 54) !important;
  }

  .responsive-content-body .video-content {
    background-color: rgb(32, 43, 54) !important;
  }

  .bg-cfi {
    background-color: rgb(32, 43, 54) !important;
  }

  .course-body {
    background-color: $gray_dark_mode !important;
    color: $white !important;

    .toolbar {
      background-color: rgb(12, 24, 35) !important;

      .toolbar-button {
        &:hover {
          background-color: rgba(255, 255, 255, 0.15);
        }
      }

      .exit-button {
        .button {
          color: $primary_blue;

          .sportys-icon {
            filter: brightness(1) !important;
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.15) !important;
          }
        }
      }
    }
  }

  .content {
    background-color: $gray_dark_mode !important;
    color: $white !important;
  }

  .content h2 {
    color: $white !important;
  }

  .content > .resource {
    color: $white !important;
    background-color: $lightgray_dark_mode !important;
  }

  .sidenav {
    color: $white !important;
  }

  .content-body {
    background-color: $lightgray_dark_mode !important;
    color: $white !important;

    // .responsive-content-footer{
    //   background-color: rgb(32,43,54) !important;
    //   width: 100vw;
    //   height: 100vh;
    // }
  }

  .route-container {
    background-color: $gray_dark_mode !important;
    color: $white;
  }

  .sidenav {
    background-color: rgb(12, 24, 35) !important;
  }

  .plate {
    background-color: $lightgray_dark_mode !important;
    color: $white;
  }

  .card-footer {
    background-color: $lightgray_dark_mode;
    color: $white;
  }

  .card-body.bg-primary {
    background-color: $primary_blue !important;
  }

  .responsive-content-body.responsive-content-desktop {
    background-color: rgb(12, 24, 35) !important;

    .nav-next-video {
      .btn.btn-white.btn-round {
        color: $white !important;
        background-color: $primary_blue_dark_mode !important;
      }
    }
  }

  .testprep .testprep-start-session .card .card-body {
    background-color: $lightgray_dark_mode !important;
  }

  .testprep .testprep-start-session .card .card-footer {
    background-color: $lightgray_dark_mode !important;

    .btn.btn-white.btn-round {
      color: $white !important;
      background-color: $primary_blue_dark_mode !important;
    }
  }

  .text-dark {
    color: $white !important;
  }

  .plate-footer-text {
    color: $white;
  }

  .welcome_plate.plate-light_blue {
    color: $white !important;
    background-color: $lightgray_dark_mode !important;
  }

  .marketing-plate {
    .plate.plate-light_blue {
      color: $white !important;
      background-color: $lightgray_dark_mode !important;
    }
  }

  &.plane_dark {
    background: url("../src/svgs/background-plane_light.svg") no-repeat !important;
  }

  .test_prep_plate.plate.swoosh.swoosh-long-top, .test_prep_plate.plate.swoosh.swoosh-long-bottom {
    background-color: $lightgray_dark_mode !important;
    color: $white !important;
  }

  .plate.marketing {
    background-color: $lightgray_dark_mode !important;
    color: $white !important;
  }

  .test_prep_plate.plate.swoosh.swoosh-long-bottom.test-prep-review-sessions {
    background: $lightgray_dark_mode !important;
  }

  .modal-body {
    background-color: $lightgray_dark_mode;
    color: $white;
    border-bottom-left-radius: 1.73rem;
    border-bottom-right-radius: 1.73rem;

    p {
      color: $white !important;
    }

  }

  .modal-content {
    background-color: $lightgray_dark_mode;

    .close {
      text-shadow: none;
    }

    .modal-header {
      .modal-title {
        color: $white !important;
      }

      .close {
        color: $white !important;
      }

      .btn {
        color: $primary_blue_dark_mode !important;
      }
    }
  }

  .modal-header .about-modal-header {
    color: $white !important;
  }

  .question {
    color: $white !important;
  }

  .question.current {
    color: $white !important;
    background-color: rgb(32, 43, 54) !important;
  }

  .related-content-card {
    background-color: rgb(32, 43, 54) !important;
    color: $white !important;

    .description {
      .body::after {
        display: none;
      }
    }
  }

  tbody {
    tr.row-even {
      background-color: rgb(32, 43, 54) !important;
    }
  }

  .background-transparent {
    color: $white;
  }

  .background-transparent::placeholder {
    color: $white;
  }

  .border-white {
    border: solid white 0.5px;
    border-color: white !important;
    outline: none;
  }

  .border-black {
    border: solid black 0.5px !important;
    border-color: black !important;
    outline: none !important;
  }

  .form-control {
    border: 1px solid $white;
  }

  .login-form {
    form {
      .form-group {
        .form-control {
          border: 0 !important;
        }
      }

      .form-footer {
        .btn {
          background-color: $primary_blue !important;
          background-image: url("https://dl.videos.sportys.com/onlinecourse/images/planewhite@3x.png");
        }

        .btn:hover {
          color: $white !important;
          cursor: pointer;
        }
      }
    }
  }

  .login-component-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    > div {
      width: 100%;
    }

    .form-footer {
      margin: rem(3) 0px 0px 0px;
      display: flex;
      justify-content: center;
    }

    input,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      background-color: rgb(32, 43, 54) !important;
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0px 1000px rgb(32, 43, 54) inset;
      transition: background-color 5000s ease-in-out 0s;
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }

  .cfi-login-form {
    input,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      background-color: rgb(32, 43, 54) !important;
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0px 1000px rgb(32, 43, 54) inset;
      transition: background-color 5000s ease-in-out 0s;
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
    }

    select {
      border-bottom: 1px solid $type_gray !important;
    }

    .form-control {
      &.cfi-dropdown {
        color: $white;
      }
    }
  }

  .btn-primary {
    background-color: $primary_blue_dark_mode;
    color: $white !important;
  }

  .btn-locked {
    background-color: #E8A500 !important;
    color: $white !important;
  }

  .btn-ligth {
    background-color: $type_gray_dark_mode;
    color: $primary_blue_dark_mode !important;
  }

  .btn.btn-sm.btn-round.btn-shadow.btn-white {
    background-color: rgb(12, 24, 35) !important;
  }

  .btn.btn-sm.btn-white.btn-round.btn-shadow {
    background-color: rgb(32, 43, 54) !important;
  }

  .button-controls {
    .btn.btn-sm.btn-white.btn-round.btn-shadow {
      background-color: rgb(12, 24, 35) !important;
    }
  }

  .btn.btn-sm.btn-white.btn-working.btn-round.btn-shadow {
    background-color: rgb(32, 43, 54) !important;
  }

  .study-session-control {
    .btn.btn-sm.btn-white.btn-round.btn-shadow {
      background-color: $primary_blue_dark_mode !important;
    }
  }

  .first-session {
    .btn.btn-sm.btn-white.btn-round {
      background-color: $primary_blue_dark_mode !important;
      color: $white !important;
    }
  }

  .multiselect-dropdown {
    border-color: $white;
  }

  .text-color-gray {
    color: $white;
  }

  .user-info-panel-container {
    background-color: $lightgray_dark_mode !important;
  }

  .nav-item.user-thumbnail-area .user-info-panel .nav-user-info .nav-item .nav-parent .nav-link, .nav-item.navigation-link {
    color: $white !important;
  }

  .test_prep_plate.plate.swoosh {
    color: $black !important;
  }

  .external-link.text-size-14 {
    color: $white !important;
  }

  .answer {
    border: 0 !important;

    &.correct {
      border: 1px solid $inactive_gray !important;

      &.selected {
        border: 1px solid $green !important;
      }
    }

    &.incorrect {
      &.selected {
        border: 1px solid $red !important;
      }
    }
  }

  div.rounded-modal > div.modal-content {
    border-radius: 1.7rem !important;
  }

  .students-layout-menu-item {
    background-color: $lightgray_dark_mode;
    color: $white !important;
  }

  .study-session .study-session-categories > div.study-session-categories-list div.study-session-category:nth-child(odd) {
    background-color: $lightgray_dark_mode !important;
  }

  .plate .category-group-details:nth-child(even) {
    background-color: $lightgray_dark_mode !important;
  }

  .endorsement_plate, .recommended_plate {
    background-color: $lightgray_dark_mode !important;
    color: $white;
  }

  .status-content {
    color: $white !important;
  }

  p {
    &.muted {
      color: $white;
    }
  }

  .card {
    background-color: $lightgray_dark_mode !important;
  }

  .video-header {
    background-color: rgb(12, 24, 35) !important;
  }

  .welcome {
    .video_plate.plate, .guides_plate.plate, .resources_plate.plate {
      background-color: $lightgray_dark_mode !important;
      color: $white !important;
    }
  }

  .flippable-container {
    .face.front {
      background-color: rgb(32, 43, 54) !important;

      .face-text {
        background-color: rgb(32, 43, 54) !important;
      }
    }

    .face.back {
      background-color: rgb(32, 43, 54) !important;

      .face-text {
        background-color: rgb(32, 43, 54) !important;
      }
    }
  }

  .video-body .video-tabs .nav.nav-tabs {
    min-height: 35px !important;
  }

  .standard .responsive-content-header {
    background-color: $gray_dark_mode !important;
  }

  .standard .standard-video-body {
    background-color: $gray_dark_mode !important;
  }

  .standard .standard-video-body .standard-video-body-video .videos div a.video-link {
    background-color: $lightgray_dark_mode !important;
    color: $white !important;
  }

  .standard .standard-video-body .standard-video-body-controls .standard-standards ul li a {
    background-color: $lightgray_dark_mode !important;
    color: $white !important;
  }

  .marketing-video-container {
    background-color: $gray_dark_mode !important;
  }

  .marketing-content > p {
    color: $white !important;
  }

  .video {
    background-color: rgb(12, 24, 35) !important;
  }

  .video-tabs {
    background-color: rgb(12, 24, 35) !important;
    color: $white;
  }

  .nav-tabs {
    border-bottom: 0 !important;
  }

  .responsive-content-body.responsive-content-desktop ul.nav.nav-tabs li.nav-item {

    button.nav-link {
      border: 0 !important;
      color: $white !important;
      background-color: rgb(12, 24, 35) !important;
    }

    button.nav-link:hover {
      border: 0 !important;
      color: $white !important;
      background-color: rgb(32, 43, 54) !important;
    }

    button.nav-link.active {
      border: 0 !important;
      color: $white !important;
      background-color: rgb(32, 43, 54) !important;
    }
  }

  .sidenav-nav > .nav > .nav-item > .nav-parent > .nav-link {
    color: $white !important;
  }

  .sidenav .sidenav-header h3 {
    color: $white !important;
  }

  .progress-info {
    color: $type_gray_dark_mode !important;
  }

  .content .course-content-container .course-content-header .course-content-header_controls .btn-group .dropdown-item {
    background-color: $lightgray_dark_mode;
    color: $white;
  }

  .dropdown-item {
    color: $white;
  }

  .dropdown-menu.show {
    background-color: $lightgray_dark_mode;
    color: $white;

    li a {
      color: $white !important;
    }
  }

  .course-content-header_controls.area {
    .btn-group {
      button.btn.btn-white, button.btn.btn-white:hover {
        color: $white !important;
      }
    }
  }

  .sidenav-nav {
    .nav {
      .nav-item {

        &:hover {
          background-color: rgb(32, 43, 54) !important;
        }

        &.active-link {
          background-color: rgb(32, 43, 54) !important;
        }

      }
    }

    .item {
      .main, .secondary {
        &:hover {
          background-color: $lightgray_dark_mode !important;
        }

        &.active-link {
          background-color: $lightgray_dark_mode !important;
        }

        .title {
          color: $white !important;
        }
      }
    }
  }

  div.sidenav .sidenav-course-info div a {
    color: $primary_blue_dark_mode !important;
  }

  .add-new-invitation-btn {
    color: $primary_blue_dark_mode !important;
  }

  .course-list .course-list_course .course-list_name {
    color: $white !important;
  }

  .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    border-color: $white transparent !important;
  }

  .content {
    .resource {
      p {
        color: $white !important;
      }
    }
  }

  .part-1, .part-2 {
    color: $white !important;
  }

  .step-class {
    color: $primary_blue_dark_mode !important;
  }

  .table-dark-mode {

    tr {
      background-color: rgb(32, 43, 54) !important;
    }

    td {
      border-top: 0 !important;
    }
  }

  .video-data {
    a.btn.btn-sm.btn-white.btn-round.btn-shadow {
      color: $white !important;
      background-color: $primary_blue_dark_mode !important;
    }
  }

  .modal-header.about-modal-header {
    color: $white !important;
  }

  .test-prep-item {
    border-bottom: 2px solid rgb(81, 84, 87) !important;
    padding-bottom: 0 !important;
  }


  .sidenav-nav {
    .nav {
      .nav-item {
        &:not(:last-of-type) {
          &::after {
            position: absolute;
            bottom: 0;
            content: '';
            height: 1px;
            background-color: rgb(32, 43, 54) !important;
            width: calc(100% - #{rem(8)});
          }
        }
      }
    }
  }

  .status-container {
    &:not(:last-of-type) {
      &::after {
        background-color: rgb(32, 43, 54) !important;
      }
    }
  }

  hr {
    border-top: 1px solid rgb(32, 43, 54) !important;
  }

  .minified-sidebar {
    .navbar-nav {
      .nav-item {
        &::after {
          position: absolute;
          bottom: 0;
          content: "";
          height: 1px;
          background-color: rgb(32, 43, 54);
        }
      }
    }
  }

  .filter-blue-logo {
    height: 32px !important;
  }

  .btn-border-red {
    background-color: $primary_blue_dark_mode !important;
    color: $white !important;
  }

  #courseSidenav {
    background: transparent;
    border-right: 0.5px solid $lightgray_dark_mode;
  }

  .purchase-course-banner {
    border-bottom: 0.5px solid $lightgray_dark_mode;
  }

  .search-wrapper {
    #search-input {
      background-color: $lightgray_dark_mode !important;
      border: 1px solid $type_gray;
      color: $white;
    }

    .search-panel {
      background-color: $lightgray_dark_mode !important;

      .header {
        background-color: $lightgray_dark_mode !important;
      }

      .results {
        .duration-chapter {
          .duration {
            color: $medium_gray;
          }

          .chapter {
            color: $medium_gray;
          }
        }
      }
    }
  }

  .notes-main-button {
    .notes-total {
      background-color: $primary_blue !important;
    }
  }

  .notes-container {
    color: $white !important;

    .left {
      background-color: $lightgray_dark_mode !important;
      color: $white;

      .notes-list {
        .note-link {
          .text {
            &::before {
              background: linear-gradient(to left, $lightgray_dark_mode, 85%, transparent) !important;
            }
          }

          &:hover {
            background-color: $highlight_blue_dark_mode !important;

            .text {
              &::before {
                background: linear-gradient(to left, $highlight_blue_dark_mode, 85%, transparent) !important;
              }
            }
          }

          &.active {
            background-color: $primary_blue !important;

            .text {
              &::before {
                background: linear-gradient(to left, $primary_blue, 85%, transparent) !important;
              }
            }
          }
        }
      }
    }

    .right {
      background-color: $lightgray_dark_mode !important;
      color: $white;

      .mobile-menu {
        background-color: $lightergray_dark_mode !important;
      }

      .note-editor-container {
        .quill-editor {
          .ql-editor {
            &.ql-blank {
              &::before {
                color: $white;
              }
            }
          }

          .ql-toolbar {
            button {
              .ql-stroke {
                stroke: $white;
              }

              &:hover {
                .ql-stroke {
                  fill: none;
                  stroke: $primary_blue_dark_mode;
                }
              }
            }

            .ql-active {
              .ql-stroke {
                fill: none;
                stroke: $primary_blue_dark_mode;
              }
            }

            button {
              .ql-fill {
                fill: $white;
              }

              &:hover {
                .ql-fill {
                  fill: $primary_blue_dark_mode;
                  stroke: none;
                }
              }
            }

            .ql-active {
              .ql-fill {
                fill: $primary_blue_dark_mode;
                stroke: none;
              }
            }
          }
        }
      }
    }
  }

  .help-container {
    .body {
      background-color: $lightgray_dark_mode !important;

      h2 {
        color: $white;
      }

      .help-center-content {
        color: $white;
        font-family: $main-font;
      }

      .menu-card-list {
        .menu-card {
          background-color: $type_lightgray_dark_mode;
          color: $black;
        }
      }

      .summary-card {
        background-color: $type_lightgray_dark_mode;
      }

      .my-notes {
        .notes-container {
          .right {
            .notes-menu {
              background-color: $lightgray_dark_mode !important;
              border-right: 1px solid $type_lightgray_dark_mode !important;
            }
          }
        }
      }

      .block {
        color: $white !important;

        h2 {
          color: $white !important;
        }
      }
    }

    .footer {
      background-color: $lightgray_dark_mode !important;
      border-top: 1px solid $type_lightgray_dark_mode !important;

      .actions {
        .action {
          &:not(.active) {
            color: $white !important;

            .sportys-icon {
              filter: invert(1);
            }
          }
        }
      }
    }
  }

  .skeleton-wrapper {
    .skeleton {
      background-color: $lightergray_dark_mode !important;
      background-image: linear-gradient(
          105deg,
          transparent 0%,
          transparent 40%,
          $highlight_blue_dark_mode 50%,
          transparent 60%,
          transparent 100%
      ) !important;
    }
  }

  .far-page {
    .nav-content {
      background-color: rgb(12, 24, 35) !important;

      .filters {
        .top {
          .title {
            .text {
              color: $primary_blue_dark_mode !important;
            }

            .date {
              color: $white !important;
            }
          }

        }
      }

      .text {
        color: $primary_blue_dark_mode;
      }

      .status-text {
        color: $white !important;
      }

      .part-main {
        background-color: $lightergray_dark_mode;

        .title {
          color: $white;
        }

        .description {
          color: $white;
        }

        &:hover {
          filter: brightness(1.3);
        }
      }

      .section {
        background-color: $lightergray_dark_mode;

        .text {
          .title {
            color: $white !important;
          }

          .description {
            color: $white !important;
          }
        }

        &.highlighted {
          filter: brightness(1.5);
        }

        &:hover {
          filter: brightness(1.5);
        }
      }

      .blue-title {
        background-color: $dark-blue !important;
      }

      .display-more-results {
        .action {
          .button {
            color: $white;

            &:hover {
              background-color: rgba(255, 255, 255, 0.15) !important;
            }
          }
        }
      }

      .toolbar {
        background-color: rgb(12, 24, 35) !important;
      }
    }
  }

  .chat-dpe-header,
  .far-assistant-content {
    background-color: $lightgray_dark_mode !important;

    .actions {
      .button {
        &.ghost {
          color: $white;
          
          .sportys-icon {
            filter: brightness(10) !important; // white
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.35);
          }
        }
      }
    }
  }

  .chat-dpe-content,
  .far-assistant-content {
    footer {
      background-color: rgb(12, 24, 35) !important;
    }
  }

  .chat-dpe-content {
    .sidenav-content {
      .title {
        color: $primary_blue_dark_mode !important;
      }

      .blue-title {
        background-color: $dark-blue !important;
      }

      .session {
        background-color: $lightergray_dark_mode !important;

        .text {
          .title {
            color: $white !important;
          }
        }

        &.highlighted {
          filter: brightness(1.5);
        }

        &:hover {
          filter: brightness(1.5);
        }
      }
    }
  }

  .chat-dpe-main {
    background-color: $lightgray_dark_mode !important;

    .answer {
      color: $white !important;

      .bottom {
        color: $white !important;
      }
    }
  }

  .far-assistant {
    .answers-container {
      .answer {
        color: $white !important;
      }
    }
  }

  .highlight {
    background-color: darkorange !important;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
}

.color-scheme-light {
  iframe {
    background-color: $white !important;
  }

  .nav-item.user-thumbnail-area .user-info-panel .nav-user-info .nav-item .nav-parent .nav-link, .nav-item.navigation-link {
    color: $type_gray !important;
  }

  .login-form {
    form {
      .form-group, .form-control {
        background-color: $primary_blue !important;
      }
    }
  }

  .filter-blue-logo {
    filter: brightness(0) saturate(100%) invert(35%) sepia(71%) saturate(892%) hue-rotate(177deg) brightness(93%) contrast(85%);
    height: 32px !important;
  }


  .sidenav, .content-body, .user-info-panel {
    color: $black !important;
  }

  .step-class {
    color: rgba(44, 69, 109, 1) !important;
  }

  .table-dark-mode {

    tr {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }

    td {
      border-top: 0 !important;
    }
  }

  .btn-locked {
    background-color: #E8A500 !important;
    color: $black !important;
  }

  .login-component-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    > div {
      width: 100%;
    }

    .form-footer {
      margin: rem(3) 0px 0px 0px;
      display: flex;
      justify-content: center;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      --webkit-appearance: none;
      background-color: $primary_blue !important;
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0px 1000px $primary_blue inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .modal-header.about-modal-header {
      color: $black !important;
    }
  }

  .cfi-login-form {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      background-color: $primary_blue !important;
      -webkit-text-fill-color: black;
      -webkit-box-shadow: 0 0 0px 1000px $primary_blue inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .responsive-content-body {
    .test_prep_plate.plate.checkride_plate {
      background-color: $white !important;
    }
  }

  .test-prep-item {
    border-bottom: 0 !important;
  }

  .minified-sidebar {
    .navbar-nav {
      .nav-item {
        &::after {
          position: absolute;
          bottom: 0;
          content: "";
          height: 1px;
          background-color: rgb(32, 43, 54);
        }
      }
    }
  }


  .lock {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 60px;
    width: 60px;
    background-image: url('assets/images/lock-white.svg');

    background-position: center;
    background-size: contain;
    border-radius: 0px 2px 0px 0px;

    &.lock-purchase {

    }
  }

}

pdf-viewer {
  .ng2-pdf-viewer-container {
    margin: rem(4) 0px 0px 0px;
    background-color: $inactive_gray;
  }
}

pdf-viewer.modal-pdf-viewer {

  div.ng2-pdf-viewer-container {
    margin: 0;
    background-color: white !important;
  }
}


.nav-item.courier-inbox {
  padding: 0px 10px 0px 0px !important;
  margin: 0px !important;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  div.inbox-bell {
    path {
      fill: rgba(255, 255, 255, 1.0) !important;
    }

    &:hover {
      path {
        fill: rgba(255, 255, 255, 0.75) !important;
      }
    }

    > div {
      background-color: red;
    }
  }

  @media (max-width: $display_lg) {
    position: absolute;
    top: 18px;
    right: rem(32);
    min-width: unset !important;
  }
}

.cdk-global-scrollblock {
  overflow-y: hidden;
}

.cdk-overlay-container {
  z-index: 9000;

  .blue-snackbar-container {
    --mdc-snackbar-container-color: #{$certification_blue};
  }

  .warning-snackbar-container {
    --mdc-snackbar-container-color: #{$purchase_highlight};

    .mat-mdc-button.mat-mdc-snack-bar-action {
      color: $white !important;
    }
  }

  .checkout-modal-dialog {
    position: relative;

    .mat-mdc-dialog-surface {
      border-radius: rem(4);
      overflow-y: visible;

      .mat-mdc-dialog-content {
        padding: 0;
        max-height: 85vh;
        border-radius: rem(4);

        [data-braintree-id="toggle"] {
          display: none;
        }
      }
    }
  }
}
