@import "variables";

form {
  input.ng-touched.ng-invalid {
    border: 1px solid $danger;
  }

  input.ng-valid {
    border: 1px solid $primary_blue;
  }

  .form-group.form-group-icon {
    position: relative;


    > div {
      position: absolute;
      right: 0px;
      height: 38px;
      width: 38px;
      line-height: 38px;
      color: $type_gray;

      &:focus {
        color: $primary_blue;
      }
    }

    &.form-group-icon-primary {
      > div {
        color: $white;

      }

      &:focus {
        color: $white;
      }
    }
  }

  &.stylized {


    .form-control {
      padding: 0px !important;


    }

    label {
      font-weight: bold;
      margin: 0px 0px .25em 0px !important;
    }


    input[type=text], input[type=password], input[type=email], input[type=date] {
      color: $type_gray;
      border: 1px solid $type_gray;
      border-color: $type_gray;
      border-width: 0px 0px 1px 0px;
      border-radius: 0px;
      box-shadow: none !important;

      background-image: none !important;
      background-color: transparent !important;
      -webkit-box-shadow: 0 0 0 50px white inset;

      &:-webkit-autofill, &:focus:-webkit-autofill {
        background-image: none !important;
        background-color: transparent !important;
        -webkit-box-shadow: 0 0 0 50px white inset;
      }

      &::placeholder {
        color: $inactive_gray;
      }

      &:focus {
        border: 1px solid $primary_blue;
        border-width: 0px 0px 2px 0px;
        color: $primary_blue;
        border-color: $primary_blue;
        box-shadow: none;
      }
    }

    select {
      box-shadow: none;

      &:focus {
        box-shadow: none;
        border-color: $primary_blue;
      }
    }


    .form-check {

      > .checkbox {
        padding: 0px 0px 0px 15px;

        input[type=checkbox] {
          height: 16px;
          width: 16px;
          padding: 0px;
          margin: 2px 0px 0px -20px;
          line-height: 16px;
        }

        label {
          font-weight: normal;
        }
      }
    }
  }
}
