@use 'sass:math';

$primary_blue: #2972b9;
$primary_blue_dark_mode: #3791E9;
$dark-blue: #294D83;
$darker-blue: #102653;
$certification_blue: #03386b;
$certification_blue_dark_mode: #192938;
$certification_light_blue: #7CA8D3;
$disabled_blue: rgb(125, 162, 204);
$green: #61b203;
$dark_green: rgb(60, 130, 49);
$red: #dc253b;
$red-dark: #cb142a;
$type_gray: #555555;
$type_gray_dark_mode: #AFAFAF;
$type_lightgray: #EAEAEA;
$type_lightgray_dark_mode: #EBEBEB;
$inactive_gray: #a7a7a7;
$accent_gray: #eff0f1;
$background_white: #f8f8f8;
$white: #ffffff;
$lightgray: #C0C0C0;

$lightgray_dark_mode: rgb(32, 43, 54);
$lightergray_dark_mode: rgb(52, 63, 74);
$firc_gray: rgb(228, 228, 228);
$medium_gray: #AEAEB2;
$gray: #FBFBFB;
$gray_dark_mode: rgb(12, 24, 35);
$gray_accent1: #eee;
$black: #000000;
$purchase_highlight: rgb(230, 164, 38);
$purchase: rgb(249, 242, 227);
$highlight_blue: #D9E9F9;
$highlight_blue_dark_mode: rgb(82, 93, 104);
$background_blue: #F0F6FD;
$background_gray: #F0F0F0;
$callout: #fdc87e;
$lock_orange: #E8A500;
$progress_bar: rgb(139, 175, 133);

$cau_gray: #a3a3a3;
$popover-gray: #3d464f;
$progress-bar-background: rgb(203, 213, 223);
$progress-bar-fill: rgb(155, 190, 222);

$phone_sm: 320px;
$phone: 414px;
$phone_lg: 569px;
$tablet: 768px;
$tablet_xl: 818px;
$desktop: 992px;

$desktop_xl: 1200px;
$desktop_xxl: 1440px;
$desktop_xxxl: 1690px;

$display_sm: 500px;
$display_md: 725px;
$display_lg: 1075px;

$theme-colors: (
  "primary": $primary_blue,
  "danger": $red,
  "green" : $green,
  "gray" : $inactive_gray,
  "dark-gray": $type_gray,
  "light": $accent_gray
);

$grid-breakpoints: ();
$grid-breakpoints: map-merge(
    (
      xs: 0,
      sm: 500px,
      md: 725px,
      lg: 1075px,
      xl: 1200px
    ),
    $grid-breakpoints
);

$navbar-height: 60px;
$footer-height: 60px;
$navbar-logo-height: 44px;

$main-font: Jost, sans-serif;

$html-font-size: 12px;
@function stripUnit($value) {
  @return math.div($value,($value * 0 + 1));
}

@function rem($scale) {
  @return #{calc(stripUnit($scale * 3) / stripUnit($html-font-size))}rem;
}

@function calcrem($width, $scale) {
  @return 'calc(' + ($width + ' - ' + #{calc(stripUnit($scale * 3) / stripUnit($html-font-size))}rem) + ')';
}
