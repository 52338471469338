@import "variables";

.plate {
  background-color: white;
  padding: rem(8) rem(10);
  box-shadow: 0px 2px rem(3) rgba(12, 24, 35, 0.17) !important;
  border-radius: rem(3);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  justify-items: flex-start;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: rem(3);
  line-height: rem(4);
  width: 100%;

  &.plate-black {
    background-color: $black !important;
    color: $white !important;
  }

  &.plate-dark_blue {
    background-color: $certification_blue !important;
    color: $white !important;
  }

  &.plate-primary_blue {
    background-color: $primary_blue !important;
    color: $white !important;
  }

  &.plate-light_blue {
    background-color: $highlight_blue !important;
    color: $certification_blue !important;
  }

  &.plate-light_gray {
    background-color: $background_gray !important;
    color: $black !important;
  }

  > div.plate-header, > div.plate-footer {
    text-align: left;
    width: 100%;

    h2, h3 {
      font-size: 16px !important;
      font-weight: bold;
    }


  }

  > div.plate-footer {
    margin: 20px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
  }


  .plate-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 100%;

    > div {
      height: 190px;
      width: 220px;

      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;

      justify-content: stretch;
      justify-items: center;

      align-items: center;
      align-content: center;

      > div {
        margin: 10px 0px 0px 0px;
        padding: 0px;
        color: $primary;
      }

      > h3 {
        padding: 0px;
        margin: 10px 0px 0px 0px;
        font-size: 12px !important;
        font-weight: bold;
      }

      p {
        text-align: center;
        padding: 0px;
        margin: 0px 10px;
        display: block;
        flex-grow: 1
      }

      a {
        margin: 0px 0px 10px 0px;
      }
    }

    > div.category-graph {
      text-align: center;
      color: $type_gray;

      height: 193px !important;
      width: 150px !important;

      padding: 0px 0px 15px 0px;

      div.category-graph-label {
        font-weight: bold;
        height: 28px;
        line-height: 14px;
        overflow: hidden;
        color: $type_gray;
        padding: 0px;
        margin: 0px;
      }

      app-doughnut-chart {
        height: 150px;
        width: 150px;

        div {
          height: 150px;
          width: 150px;
        }
      }
    }
  }
}

@media (max-width: $phone_lg) {
  .plate {
    padding: rem(3);
  }
}
