@import "variables";

.modal {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
  max-width: inherit !important;
  z-index: 9000;

  .modal-dialog {
    max-width: inherit;

    &.modal-lg {
      max-width: 550px;


      .modal-body {
        overflow-y: auto !important;

        margin: 0px 0px 1rem 0px;
      }
    }

    &.modal-lg.modal-figure {
      /*max-height: calc(100vh - 3.5rem);*/
      height: calc(100vh - 3.5rem);
      height: calc(var(--vh, 1vh) * 100 - 7rem);
      width: 100%;

      .modal-content {
        height: 100%;

        > div {
          height: 100%;

          .modal-body {
            height: calc(100% - 40px);
          }
        }
      }
    }

    &.modal-lg.modal-maneuvers {
      height: auto !important;
      width: 600px;
      min-width: 320px;
      max-width: 600px;

      .modal-body {
        height: inherit !important;
      }
    }

    .modal-content {
      border: none !important;
    }

    .modal-header {
      border-bottom: none;
    }
  }
}
