/*Style Guide*/
@mixin H1_Centered_On-Light {
  font-family: Lato;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  color: $black;
}


@mixin H2_Centered_On-Light {
  font-family: Lato;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  color: $black;
}

@mixin H4_Centered_On-Light {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $primary_blue;
}


@mixin H2_Centered_On-Dark {
  font-family: Lato;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  color: $white;
}

@mixin H4_Centered_On-Dark {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $white;
}


@mixin H2_Left-Aligned_On-Light {
  font-family: Lato;
  font-size: 30px;
  font-weight: 300;
  color: $black;
}


@mixin H4_Left-Aligned_On-Light {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  color: $primary_blue;
}


@mixin Body_Centered_On-Light {
  font-family: Lato;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: $type_gray;
}

@mixin Body_Left-Aligned_On-Dark {
  font-family: Lato;
  font-size: 20px;
  line-height: 1.9;
  color: $white;
}


@mixin Caption_Centered_On-Dark {
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: $white;
}


@mixin Caption_Centered_On-Light {
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: $primary_blue;
}

@mixin Caption_Centered_On-Light_Secondary {
  font-family: Lato;
  font-size: 16px;
  line-height: 1.63;
  text-align: center;
  color: $type_gray;
}


@mixin Text-Style {
  font-family: Lato;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
}

@mixin H3_Left-Aligned_On-Light {
  font-family: Lato;
  font-size: 21px;
  color: rgba(0, 0, 0, 0.87);
}

@mixin Caption_Left-Aligned_Certification-blue {
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  color: $certification_blue;
}

@mixin Caption-2_Left-Aligned_Inactive-Gray {
  font-family: Lato;
  font-size: 12px;
  line-height: 1.58;
  color: $inactive_gray;
}

@mixin Caption-2_Left-Aligned_Red {
  font-family: Lato;
  font-size: 12px;
  line-height: 1.58;
  color: $red;
}

@mixin Rectangle-Copy-3 {
  width: 99px;
  height: 32px;
  border-radius: 22px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.23);
  background-color: $white;
}

@mixin Course_H1{
  font-size: 20px;
  font-family: Lato;
  font-weight: 700;
}
@mixin Course_H2{
  font-size: 16px;
  font-family: Lato;
  font-weight: 700;
}
@mixin Course_H3{
  font-size: 14px;
  font-family: Lato;
  font-weight: 400;
}
@mixin Course_H3_Dark{
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
}
@mixin Course_H4{
  font-size: 12px;
  font-family: Lato;
  font-weight: 400;
}
@mixin Course_H4_Dark{
  font-size: 12px;
  font-family: Lato;
  font-weight: 700;
}
@mixin Course_H5{
  font-size: 12px;
  font-family: Lato;
  font-weight: 400;
}


@mixin Course_TH{
  font-size: 12px;
  font-family: Lato;
  font-weight: 400;
}
@mixin Course_TH_bold{
  font-size: 12px;
  font-family: Lato;
  font-weight: 700;
}

@mixin Course_Text_Xs{
  font-size: 10px;
  font-family: Lato;
  font-weight: 700;
}

@mixin Course_Text_Sm{
  font-size: 12px;
  font-family: Lato;
  font-weight: 400;
}
@mixin Course_Text_Sm_Bold{
  font-size: 12px;
  font-family: Lato;
  font-weight: 700;

}

@mixin Course_Text_Md{
  font-size: 14px;
  font-family: Lato;
  font-weight: 400;
}

@mixin Course_Text_Md_Bold{
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
}

@mixin Course_Text{
  font-size: 16px;
  font-family: Lato;
  font-weight: 400;
}

@mixin Course_Text_Bold{
  font-size: 16px;
  font-family: Lato;
  font-weight: 700;
}

@mixin Course_Text_Lg{
  font-size: 20px;
  font-family: Lato;
  font-weight: 400;
}
@mixin Course_Text_Lg_Bold{
  font-size: 20px;
  font-family: Lato;
  font-weight: 700;
}
