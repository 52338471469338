@import "variables";

div.swoosh {
  background-clip: border-box !important;
  background-position: 75% 50% !important;
  background-size: cover !important;

  &.swoosh-cfi-home {
    background: url("../backgrounds/CFI-Home-Swoosh.png") no-repeat;
    background-size: contain !important;
    background-position: right !important;
  }

  &.swoosh-cfi-firc {
    background: url("../backgrounds/CFI-FIRC-Swoosh.png") no-repeat;
    background-size: contain !important;
    background-position: right !important;
  }

  &.swoosh-cfi-resources {
    background: url("../backgrounds/CFI-Resources-Swoosh.png") no-repeat;
    background-size: contain !important;
    background-position: right !important;
  }
  
  &.swoosh-cfi-students {
    background: url("../backgrounds/CFI-Student-Swoosh.png") no-repeat;
    background-size: contain !important;
    background-position: right !important;
  }
  
  &.swoosh-cfi-special-offers {
    background: url("../backgrounds/CFI-Special-Offers-Swoosh.png") no-repeat;
    background-size: contain !important;
    background-position: right !important;
  }

  &.swoosh-cfi-student-course-progress {
    background: url("../backgrounds/CFI-Student-Course-Progress-Swoosh.png") no-repeat;
    background-size: contain !important;
    background-position: right !important;
  }

  &.swoosh-long-bottom {
    background: url("../backgrounds/Swoosh-Long-Bottom@3x.png") no-repeat;
  }

  &.swoosh-long-top {
    background: url("../backgrounds/Swoosh-Long-Top@3x.png") no-repeat;
  }

  &.swoosh-short-thicker {
    background: url("../backgrounds/Swoosh-Short-Thicker@3x.png") no-repeat;
  }

  &.swoosh-short-bottom {
    background: url("../backgrounds/Swoosh-Short-Bottom@3x.png") no-repeat;
  }
}

div.plane{
  background-clip: border-box;
  background-position: 100% 100% !important;
  background-size: contain;

  &.plane_light{
    background: url("../../svgs/background-plane_light.svg") no-repeat;
  }
  &.plane_dark{
    background: url("../../svgs/background-plane_dark.svg") no-repeat;
  }
}

div.plate {
  &.blue {
    background-color: $certification_blue;
    color: $white;
  }
  &.gray_accent1 {
    background-color: $gray_accent1;
  }
}

@media (max-width: $desktop_xl) {
  div.plane {
    //background-position: 75% 50% !important;
    background: none !important;
  }
  div.swoosh{
    background-position: 50% 50% !important;
  }
}
