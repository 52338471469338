%svg-common {
    background: url("assets/sportys.css-93a83235.svg") no-repeat;
}

.sportys-All-Videos {
    @extend %svg-common;
    background-position: 0 61.53453453453454%;
}

.sportys-All-Videos-icon {
    width: 160px;
    height: 160px;
}

.sportys-background-plane_dark {
    @extend %svg-common;
    background-position: 67.19309262166405% 0;
}

.sportys-background-plane_dark-icon {
    width: 221px;
    height: 131px;
}

.sportys-background-plane_light {
    @extend %svg-common;
    background-position: 67.19309262166405% 18.848920863309353%;
}

.sportys-background-plane_light-icon {
    width: 221px;
    height: 131px;
}

.sportys-badge-v1-endorsement_requested {
    @extend %svg-common;
    background-position: 22.922636103151863% 61.53453453453454%;
}

.sportys-badge-v1-endorsement_requested-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v1-first_80 {
    @extend %svg-common;
    background-position: 45.845272206303726% 61.53453453453454%;
}

.sportys-badge-v1-first_80-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v1-first_80_large_blue {
    @extend %svg-common;
    background-position: 68.76790830945559% 61.53453453453454%;
}

.sportys-badge-v1-first_80_large_blue-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v1-first_80_large_gray {
    @extend %svg-common;
    background-position: 92.98280802292264% 0;
}

.sportys-badge-v1-first_80_large_gray-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v1-first_80_mini_blue {
    @extend %svg-common;
    background-position: 52.84197530864198% 41.90231362467866%;
}

.sportys-badge-v1-first_80_mini_blue-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge-v1-first_solo {
    @extend %svg-common;
    background-position: 92.98280802292264% 24.024024024024023%;
}

.sportys-badge-v1-first_solo-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v1-incomplete_all_videos {
    @extend %svg-common;
    background-position: 92.98280802292264% 48.048048048048045%;
}

.sportys-badge-v1-incomplete_all_videos-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v1-incomplete_endorsement {
    @extend %svg-common;
    background-position: 0 85.55855855855854%;
}

.sportys-badge-v1-incomplete_endorsement-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v1-incomplete_first_80 {
    @extend %svg-common;
    background-position: 22.922636103151863% 85.55855855855854%;
}

.sportys-badge-v1-incomplete_first_80-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v1-incomplete_first_solo {
    @extend %svg-common;
    background-position: 45.845272206303726% 85.55855855855854%;
}

.sportys-badge-v1-incomplete_first_solo-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v1-incomplete_second_80 {
    @extend %svg-common;
    background-position: 68.76790830945559% 85.55855855855854%;
}

.sportys-badge-v1-incomplete_second_80-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v1-second_80 {
    @extend %svg-common;
    background-position: 91.69054441260745% 85.55855855855854%;
}

.sportys-badge-v1-second_80-icon {
    width: 160px;
    height: 160px;
}

.sportys-badge-v2-all_videos_watched {
    @extend %svg-common;
    background-position: 58.7679012345679% 41.90231362467866%;
}

.sportys-badge-v2-all_videos_watched-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge-v2-endorsement_requested {
    @extend %svg-common;
    background-position: 64.69382716049383% 41.90231362467866%;
}

.sportys-badge-v2-endorsement_requested-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge-v2-first_80 {
    @extend %svg-common;
    background-position: 70.61975308641975% 41.90231362467866%;
}

.sportys-badge-v2-first_80-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge-v2-first_solo {
    @extend %svg-common;
    background-position: 0 93.80719794344473%;
}

.sportys-badge-v2-first_solo-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge-v2-second_80 {
    @extend %svg-common;
    background-position: 5.925925925925926% 93.80719794344473%;
}

.sportys-badge-v2-second_80-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge_mini-v1-all_videos_watched {
    @extend %svg-common;
    background-position: 11.851851851851851% 93.80719794344473%;
}

.sportys-badge_mini-v1-all_videos_watched-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge_mini-v1-endorsement_requested {
    @extend %svg-common;
    background-position: 17.77777777777778% 93.80719794344473%;
}

.sportys-badge_mini-v1-endorsement_requested-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge_mini-v1-first_80 {
    @extend %svg-common;
    background-position: 23.703703703703702% 93.68677792041078%;
}

.sportys-badge_mini-v1-first_80-icon {
    width: 48px;
    height: 47px;
}

.sportys-badge_mini-v1-first_80_mini_gray {
    @extend %svg-common;
    background-position: 99.87901234567902% 0;
}

.sportys-badge_mini-v1-first_80_mini_gray-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge_mini-v1-first_solo {
    @extend %svg-common;
    background-position: 68.64444444444445% 33.71943371943372%;
}

.sportys-badge_mini-v1-first_solo-icon {
    width: 48px;
    height: 49px;
}

.sportys-badge_mini-v1-incomplete_all_videos {
    @extend %svg-common;
    background-position: 99.87901234567902% 6.169665809768637%;
}

.sportys-badge_mini-v1-incomplete_all_videos-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge_mini-v1-incomplete_endorsement {
    @extend %svg-common;
    background-position: 99.87901234567902% 12.339331619537274%;
}

.sportys-badge_mini-v1-incomplete_endorsement-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge_mini-v1-incomplete_first_80 {
    @extend %svg-common;
    background-position: 99.87901234567902% 18.508997429305914%;
}

.sportys-badge_mini-v1-incomplete_first_80-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge_mini-v1-incomplete_first_solo {
    @extend %svg-common;
    background-position: 99.87901234567902% 24.67866323907455%;
}

.sportys-badge_mini-v1-incomplete_first_solo-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge_mini-v1-incomplete_second_80 {
    @extend %svg-common;
    background-position: 99.87901234567902% 30.848329048843187%;
}

.sportys-badge_mini-v1-incomplete_second_80-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge_mini-v1-second_80 {
    @extend %svg-common;
    background-position: 99.87901234567902% 37.01799485861183%;
}

.sportys-badge_mini-v1-second_80-icon {
    width: 48px;
    height: 48px;
}

.sportys-badge_mini-v1-third_80 {
    @extend %svg-common;
    background-position: 99.87901234567902% 43.18766066838046%;
}

.sportys-badge_mini-v1-third_80-icon {
    width: 48px;
    height: 48px;
}

.sportys-glyph-add {
    @extend %svg-common;
    background-position: 73.7410071942446% 91%;
}

.sportys-glyph-add-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-add-note {
    @extend %svg-common;
    background-position: 37.81512605042017% 91.11360799001248%;
}

.sportys-glyph-add-note-icon {
    width: 25px;
    height: 25px;
}

.sportys-glyph-ai-stars {
    @extend %svg-common;
    background-position: 40.816326530612244% 91%;
}

.sportys-glyph-ai-stars-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-ai-stars-dark {
    @extend %svg-common;
    background-position: 43.81752701080432% 91%;
}

.sportys-glyph-ai-stars-dark-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-ai-stars-white {
    @extend %svg-common;
    background-position: 46.8187274909964% 91%;
}

.sportys-glyph-ai-stars-white-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-archive-tick {
    @extend %svg-common;
    background-position: 57.865546218487395% 46.75%;
}

.sportys-glyph-archive-tick-icon {
    width: 25px;
    height: 26px;
}

.sportys-glyph-archive-tick-bold {
    @extend %svg-common;
    background-position: 60.86674669867947% 46.75%;
}

.sportys-glyph-archive-tick-bold-icon {
    width: 25px;
    height: 26px;
}

.sportys-glyph-archive-tick-bold-dark {
    @extend %svg-common;
    background-position: 63.867947178871546% 46.75%;
}

.sportys-glyph-archive-tick-bold-dark-icon {
    width: 25px;
    height: 26px;
}

.sportys-glyph-archive-tick-dark {
    @extend %svg-common;
    background-position: 66.86914765906363% 46.75%;
}

.sportys-glyph-archive-tick-dark-icon {
    width: 25px;
    height: 26px;
}

.sportys-glyph-arrow-bottom {
    @extend %svg-common;
    background-position: 99.76512455516014% 79.21760391198045%;
}

.sportys-glyph-arrow-bottom-icon {
    width: 15px;
    height: 8px;
}

.sportys-glyph-arrow-down {
    @extend %svg-common;
    background-position: 67.45518867924528% 38.11274509803921%;
}

.sportys-glyph-arrow-down-icon {
    width: 10px;
    height: 10px;
}

.sportys-glyph-arrow-right {
    @extend %svg-common;
    background-position: 99.88291517323775% 88.57142857142857%;
}

.sportys-glyph-arrow-right-icon {
    width: 21px;
    height: 21px;
}

.sportys-glyph-arrow-rotate-left {
    @extend %svg-common;
    background-position: 76.61870503597122% 91%;
}

.sportys-glyph-arrow-rotate-left-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-arrow-square-left {
    @extend %svg-common;
    background-position: 97.35499398315282% 82.47809762202753%;
}

.sportys-glyph-arrow-square-left-icon {
    width: 27px;
    height: 27px;
}

.sportys-glyph-arrow-square-left-dark {
    @extend %svg-common;
    background-position: 97.35499398315282% 85.85732165206508%;
}

.sportys-glyph-arrow-square-left-dark-icon {
    width: 27px;
    height: 27px;
}

.sportys-glyph-arrow-square-right {
    @extend %svg-common;
    background-position: 97.35499398315282% 89.23654568210263%;
}

.sportys-glyph-arrow-square-right-icon {
    width: 27px;
    height: 27px;
}

.sportys-glyph-arrow-square-right-dark {
    @extend %svg-common;
    background-position: 97.35499398315282% 92.61576971214018%;
}

.sportys-glyph-arrow-square-right-dark-icon {
    width: 27px;
    height: 27px;
}

.sportys-glyph-arrow-top {
    @extend %svg-common;
    background-position: 95.96915776986951% 93.76528117359413%;
}

.sportys-glyph-arrow-top-icon {
    width: 15px;
    height: 8px;
}

.sportys-glyph-arrow-up {
    @extend %svg-common;
    background-position: 99.88291517323775% 91.92546583850931%;
}

.sportys-glyph-arrow-up-icon {
    width: 21px;
    height: 21px;
}

.sportys-glyph-back {
    @extend %svg-common;
    background-position: 79.49640287769785% 91%;
}

.sportys-glyph-back-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-back-white {
    @extend %svg-common;
    background-position: 82.37410071942446% 91%;
}

.sportys-glyph-back-white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-blue-star {
    @extend %svg-common;
    background-position: 73.98806682577566% 43.67245657568238%;
}

.sportys-glyph-blue-star-icon {
    width: 20px;
    height: 20px;
}

.sportys-glyph-calculator {
    @extend %svg-common;
    background-position: 85.25179856115108% 91%;
}

.sportys-glyph-calculator-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-candle {
    @extend %svg-common;
    background-position: 72.68592057761732% 32.75%;
}

.sportys-glyph-candle-icon {
    width: 27px;
    height: 26px;
}

.sportys-glyph-candle-bold {
    @extend %svg-common;
    background-position: 74.61131167268351% 40.75%;
}

.sportys-glyph-candle-bold-icon {
    width: 27px;
    height: 26px;
}

.sportys-glyph-candle-bold-dark {
    @extend %svg-common;
    background-position: 51.50661853188929% 46.75%;
}

.sportys-glyph-candle-bold-dark-icon {
    width: 27px;
    height: 26px;
}

.sportys-glyph-candle-dark {
    @extend %svg-common;
    background-position: 54.75571600481348% 46.75%;
}

.sportys-glyph-candle-dark-icon {
    width: 27px;
    height: 26px;
}

.sportys-glyph-categories {
    @extend %svg-common;
    background-position: 88.12949640287769% 91%;
}

.sportys-glyph-categories-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-category-breakdown {
    @extend %svg-common;
    background-position: 91.00719424460432% 91%;
}

.sportys-glyph-category-breakdown-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-check_grey {
    @extend %svg-common;
    background-position: 75.12142857142857% 46.28712871287129%;
}

.sportys-glyph-check_grey-icon {
    width: 18px;
    height: 18px;
}

.sportys-glyph-checkride_prep {
    @extend %svg-common;
    background-position: 93.88489208633094% 91%;
}

.sportys-glyph-checkride_prep-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-circle-stroke {
    @extend %svg-common;
    background-position: 0 96.98503740648378%;
}

.sportys-glyph-circle-stroke-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-correct {
    @extend %svg-common;
    background-position: 2.8776978417266186% 96.98503740648378%;
}

.sportys-glyph-correct-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-correct_green {
    @extend %svg-common;
    background-position: 5.755395683453237% 96.98503740648378%;
}

.sportys-glyph-correct_green-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-courses {
    @extend %svg-common;
    background-position: 99.02325581395348% 53.625%;
}

.sportys-glyph-courses-icon {
    width: 41px;
    height: 26px;
}

.sportys-glyph-down {
    @extend %svg-common;
    background-position: 8.633093525179856% 96.98503740648378%;
}

.sportys-glyph-down-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-down-white {
    @extend %svg-common;
    background-position: 11.510791366906474% 96.98503740648378%;
}

.sportys-glyph-down-white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-dropdown {
    @extend %svg-common;
    background-position: 14.388489208633093% 96.98503740648378%;
}

.sportys-glyph-dropdown-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-edit-pen {
    @extend %svg-common;
    background-position: 17.26618705035971% 96.98503740648378%;
}

.sportys-glyph-edit-pen-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-edit-profile {
    @extend %svg-common;
    background-position: 20.14388489208633% 96.98503740648378%;
}

.sportys-glyph-edit-profile-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-envelope {
    @extend %svg-common;
    background-position: 99.02325581395348% 57.8880407124682%;
}

.sportys-glyph-envelope-icon {
    width: 41px;
    height: 40px;
}

.sportys-glyph-envelope-white {
    @extend %svg-common;
    background-position: 98.30133657351155% 71.78526841448189%;
}

.sportys-glyph-envelope-white-icon {
    width: 35px;
    height: 25px;
}

.sportys-glyph-faa-three-rectangles {
    @extend %svg-common;
    background-position: 23.02158273381295% 96.98503740648378%;
}

.sportys-glyph-faa-three-rectangles-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-fars {
    @extend %svg-common;
    background-position: 25.899280575539567% 96.98503740648378%;
}

.sportys-glyph-fars-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-firc {
    @extend %svg-common;
    background-position: 28.776978417266186% 96.98503740648378%;
}

.sportys-glyph-firc-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-flashcards {
    @extend %svg-common;
    background-position: 31.654676258992804% 96.98503740648378%;
}

.sportys-glyph-flashcards-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-gift_card_white {
    @extend %svg-common;
    background-position: 97.94430992736078% 75.56675062972292%;
}

.sportys-glyph-gift_card_white-icon {
    width: 32px;
    height: 32px;
}

.sportys-glyph-giftcard {
    @extend %svg-common;
    background-position: 69.78657074340528% 46.75%;
}

.sportys-glyph-giftcard-icon {
    width: 24px;
    height: 26px;
}

.sportys-glyph-go-top-arrow {
    @extend %svg-common;
    background-position: 98.90220048899755% 68.06615776081425%;
}

.sportys-glyph-go-top-arrow-icon {
    width: 40px;
    height: 40px;
}

.sportys-glyph-grade {
    @extend %svg-common;
    background-position: 34.53237410071942% 96.98503740648378%;
}

.sportys-glyph-grade-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-grade_white {
    @extend %svg-common;
    background-position: 37.410071942446045% 96.98503740648378%;
}

.sportys-glyph-grade_white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-graduation {
    @extend %svg-common;
    background-position: 40.28776978417266% 96.98503740648378%;
}

.sportys-glyph-graduation-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-graduation-grey {
    @extend %svg-common;
    background-position: 99.02325581395348% 62.97709923664122%;
}

.sportys-glyph-graduation-grey-icon {
    width: 41px;
    height: 40px;
}

.sportys-glyph-hardware {
    @extend %svg-common;
    background-position: 43.16546762589928% 96.98503740648378%;
}

.sportys-glyph-hardware-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-help-center-hangar {
    @extend %svg-common;
    background-position: 53.90680100755667% 34.38320209973753%;
}

.sportys-glyph-help-center-hangar-icon {
    width: 64px;
    height: 64px;
}

.sportys-glyph-help-center-home {
    @extend %svg-common;
    background-position: 49.81992797118848% 91%;
}

.sportys-glyph-help-center-home-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-help-center-home-blue {
    @extend %svg-common;
    background-position: 52.82112845138055% 91%;
}

.sportys-glyph-help-center-home-blue-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-home {
    @extend %svg-common;
    background-position: 46.0431654676259% 96.98503740648378%;
}

.sportys-glyph-home-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-incorrect {
    @extend %svg-common;
    background-position: 48.92086330935252% 96.98503740648378%;
}

.sportys-glyph-incorrect-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-incorrect_red {
    @extend %svg-common;
    background-position: 51.798561151079134% 96.98503740648378%;
}

.sportys-glyph-incorrect_red-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-incorrect_white {
    @extend %svg-common;
    background-position: 54.67625899280576% 96.98503740648378%;
}

.sportys-glyph-incorrect_white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-info {
    @extend %svg-common;
    background-position: 57.55395683453237% 96.98503740648378%;
}

.sportys-glyph-info-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-info-gray {
    @extend %svg-common;
    background-position: 55.82232893157263% 91%;
}

.sportys-glyph-info-gray-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-info-red {
    @extend %svg-common;
    background-position: 60.431654676258994% 96.98503740648378%;
}

.sportys-glyph-info-red-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-info-white {
    @extend %svg-common;
    background-position: 58.8235294117647% 91%;
}

.sportys-glyph-info-white-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-left-arrow {
    @extend %svg-common;
    background-position: 63.30935251798561% 96.98503740648378%;
}

.sportys-glyph-left-arrow-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-left-arrow-blue {
    @extend %svg-common;
    background-position: 99.88361045130641% 73.80073800738008%;
}

.sportys-glyph-left-arrow-blue-icon {
    width: 16px;
    height: 13px;
}

.sportys-glyph-left-arrow-white {
    @extend %svg-common;
    background-position: 99.88361045130641% 75.39975399753997%;
}

.sportys-glyph-left-arrow-white-icon {
    width: 16px;
    height: 13px;
}

.sportys-glyph-lock {
    @extend %svg-common;
    background-position: 66.18705035971223% 96.98503740648378%;
}

.sportys-glyph-lock-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-lock-black {
    @extend %svg-common;
    background-position: 99.88361045130641% 78.0246913580247%;
}

.sportys-glyph-lock-black-icon {
    width: 16px;
    height: 16px;
}

.sportys-glyph-lock-blue {
    @extend %svg-common;
    background-position: 69.06474820143885% 96.98503740648378%;
}

.sportys-glyph-lock-blue-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-lock-orange {
    @extend %svg-common;
    background-position: 71.94244604316546% 96.98503740648378%;
}

.sportys-glyph-lock-orange-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-lock-orange-rounded {
    @extend %svg-common;
    background-position: 74.82014388489209% 96.98503740648378%;
}

.sportys-glyph-lock-orange-rounded-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-lock-white {
    @extend %svg-common;
    background-position: 77.6978417266187% 96.98503740648378%;
}

.sportys-glyph-lock-white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-lock-white-orange-rounded {
    @extend %svg-common;
    background-position: 80.57553956834532% 96.98503740648378%;
}

.sportys-glyph-lock-white-orange-rounded-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-lock-white-small {
    @extend %svg-common;
    background-position: 33.2541567695962% 93.31111111111112%;
}

.sportys-glyph-lock-white-small-icon {
    width: 16px;
    height: 16px;
}

.sportys-glyph-locked-plate {
    @extend %svg-common;
    background-position: 83.42159383033419% 64.343163538874%;
}

.sportys-glyph-locked-plate-icon {
    width: 80px;
    height: 80px;
}

.sportys-glyph-login {
    @extend %svg-common;
    background-position: 83.45323741007195% 96.98503740648378%;
}

.sportys-glyph-login-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-login_white {
    @extend %svg-common;
    background-position: 86.33093525179856% 96.98503740648378%;
}

.sportys-glyph-login_white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-maneuvers {
    @extend %svg-common;
    background-position: 89.20863309352518% 96.98503740648378%;
}

.sportys-glyph-maneuvers-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-marked {
    @extend %svg-common;
    background-position: 92.0863309352518% 96.98503740648378%;
}

.sportys-glyph-marked-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-marked_filled {
    @extend %svg-common;
    background-position: 94.96402877697842% 96.98503740648378%;
}

.sportys-glyph-marked_filled-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-microsoft {
    @extend %svg-common;
    background-position: 97.84172661870504% 96.98503740648378%;
}

.sportys-glyph-microsoft-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-minus {
    @extend %svg-common;
    background-position: 0 99.97755610972568%;
}

.sportys-glyph-minus-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-new-note {
    @extend %svg-common;
    background-position: 2.8776978417266186% 99.97755610972568%;
}

.sportys-glyph-new-note-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-next {
    @extend %svg-common;
    background-position: 5.755395683453237% 99.97755610972568%;
}

.sportys-glyph-next-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-no_results {
    @extend %svg-common;
    background-position: 93.70437017994858% 64.343163538874%;
}

.sportys-glyph-no_results-icon {
    width: 80px;
    height: 80px;
}

.sportys-glyph-notes {
    @extend %svg-common;
    background-position: 61.824729891956785% 91%;
}

.sportys-glyph-notes-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-notes-blue {
    @extend %svg-common;
    background-position: 64.82593037214886% 91%;
}

.sportys-glyph-notes-blue-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-notes-list {
    @extend %svg-common;
    background-position: 99.52619047619048% 82.06724782067248%;
}

.sportys-glyph-notes-list-icon {
    width: 18px;
    height: 23px;
}

.sportys-glyph-notifications {
    @extend %svg-common;
    background-position: 97.94430992736078% 79.09887359198999%;
}

.sportys-glyph-notifications-icon {
    width: 32px;
    height: 27px;
}

.sportys-glyph-play {
    @extend %svg-common;
    background-position: 8.633093525179856% 99.97755610972568%;
}

.sportys-glyph-play-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-play-white {
    @extend %svg-common;
    background-position: 11.510791366906474% 99.97755610972568%;
}

.sportys-glyph-play-white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-plus {
    @extend %svg-common;
    background-position: 14.388489208633093% 99.97755610972568%;
}

.sportys-glyph-plus-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-plus-simple-blue {
    @extend %svg-common;
    background-position: 17.26618705035971% 99.97755610972568%;
}

.sportys-glyph-plus-simple-blue-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-plus-simple-white {
    @extend %svg-common;
    background-position: 20.14388489208633% 99.97755610972568%;
}

.sportys-glyph-plus-simple-white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-preferences {
    @extend %svg-common;
    background-position: 23.02158273381295% 99.97755610972568%;
}

.sportys-glyph-preferences-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-pressure-outlined {
    @extend %svg-common;
    background-position: 99.63300492610837% 49.16773367477593%;
}

.sportys-glyph-pressure-outlined-icon {
    width: 46px;
    height: 45px;
}

.sportys-glyph-privateguide {
    @extend %svg-common;
    background-position: 25.899280575539567% 99.97755610972568%;
}

.sportys-glyph-privateguide-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-random {
    @extend %svg-common;
    background-position: 28.776978417266186% 99.97755610972568%;
}

.sportys-glyph-random-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-resource-file {
    @extend %svg-common;
    background-position: 61.967254408060455% 34.38320209973753%;
}

.sportys-glyph-resource-file-icon {
    width: 64px;
    height: 64px;
}

.sportys-glyph-resources {
    @extend %svg-common;
    background-position: 31.654676258992804% 99.97755610972568%;
}

.sportys-glyph-resources-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-retry {
    @extend %svg-common;
    background-position: 34.53237410071942% 99.97755610972568%;
}

.sportys-glyph-retry-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-right-arrow {
    @extend %svg-common;
    background-position: 99.41342756183745% 70.98765432098766%;
}

.sportys-glyph-right-arrow-icon {
    width: 9px;
    height: 16px;
}

.sportys-glyph-right-arrow-blue {
    @extend %svg-common;
    background-position: 74.34916864608076% 35.42435424354244%;
}

.sportys-glyph-right-arrow-blue-icon {
    width: 16px;
    height: 13px;
}

.sportys-glyph-right-arrow-white {
    @extend %svg-common;
    background-position: 66.03562945368171% 38.25338253382534%;
}

.sportys-glyph-right-arrow-white-icon {
    width: 16px;
    height: 13px;
}

.sportys-glyph-rounded-tick {
    @extend %svg-common;
    background-position: 37.410071942446045% 99.97755610972568%;
}

.sportys-glyph-rounded-tick-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-search-normal {
    @extend %svg-common;
    background-position: 99.88291517323775% 85.32338308457712%;
}

.sportys-glyph-search-normal-icon {
    width: 21px;
    height: 22px;
}

.sportys-glyph-see-all-courses {
    @extend %svg-common;
    background-position: 28.639618138424822% 93.7741935483871%;
}

.sportys-glyph-see-all-courses-icon {
    width: 20px;
    height: 20px;
}

.sportys-glyph-see-all-courses-dark {
    @extend %svg-common;
    background-position: 31.026252983293556% 93.7741935483871%;
}

.sportys-glyph-see-all-courses-dark-icon {
    width: 20px;
    height: 20px;
}

.sportys-glyph-share {
    @extend %svg-common;
    background-position: 40.28776978417266% 99.97755610972568%;
}

.sportys-glyph-share-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-share-white {
    @extend %svg-common;
    background-position: 43.16546762589928% 99.97755610972568%;
}

.sportys-glyph-share-white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-smart_study {
    @extend %svg-common;
    background-position: 46.0431654676259% 99.97755610972568%;
}

.sportys-glyph-smart_study-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-special_offers {
    @extend %svg-common;
    background-position: 72.2511961722488% 35.467980295566505%;
}

.sportys-glyph-special_offers-icon {
    width: 22px;
    height: 14px;
}

.sportys-glyph-students {
    @extend %svg-common;
    background-position: 48.92086330935252% 99.97755610972568%;
}

.sportys-glyph-students-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-subtract {
    @extend %svg-common;
    background-position: 51.798561151079134% 99.97755610972568%;
}

.sportys-glyph-subtract-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-support {
    @extend %svg-common;
    background-position: 67.82713085234094% 91%;
}

.sportys-glyph-support-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-support-blue {
    @extend %svg-common;
    background-position: 70.82833133253301% 91%;
}

.sportys-glyph-support-blue-icon {
    width: 25px;
    height: 24px;
}

.sportys-glyph-test {
    @extend %svg-common;
    background-position: 54.67625899280576% 99.97755610972568%;
}

.sportys-glyph-test-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-test_prep {
    @extend %svg-common;
    background-position: 57.55395683453237% 99.97755610972568%;
}

.sportys-glyph-test_prep-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-thumbs-down {
    @extend %svg-common;
    background-position: 60.431654676258994% 99.97755610972568%;
}

.sportys-glyph-thumbs-down-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-thumbs-down-bold {
    @extend %svg-common;
    background-position: 63.30935251798561% 99.97755610972568%;
}

.sportys-glyph-thumbs-down-bold-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-thumbs-up {
    @extend %svg-common;
    background-position: 66.18705035971223% 99.97755610972568%;
}

.sportys-glyph-thumbs-up-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-thumbs-up-bold {
    @extend %svg-common;
    background-position: 69.06474820143885% 99.97755610972568%;
}

.sportys-glyph-thumbs-up-bold-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-tick-white {
    @extend %svg-common;
    background-position: 71.94244604316546% 99.97755610972568%;
}

.sportys-glyph-tick-white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-training {
    @extend %svg-common;
    background-position: 74.82014388489209% 99.97755610972568%;
}

.sportys-glyph-training-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-trash {
    @extend %svg-common;
    background-position: 75.12142857142857% 32.546583850931675%;
}

.sportys-glyph-trash-icon {
    width: 18px;
    height: 21px;
}

.sportys-glyph-unavailable {
    @extend %svg-common;
    background-position: 35.15439429928741% 93.31111111111112%;
}

.sportys-glyph-unavailable-icon {
    width: 16px;
    height: 16px;
}

.sportys-glyph-up {
    @extend %svg-common;
    background-position: 77.6978417266187% 99.97755610972568%;
}

.sportys-glyph-up-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-up-white {
    @extend %svg-common;
    background-position: 80.57553956834532% 99.97755610972568%;
}

.sportys-glyph-up-white-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-upload {
    @extend %svg-common;
    background-position: 83.45323741007195% 99.97755610972568%;
}

.sportys-glyph-upload-icon {
    width: 24px;
    height: 24px;
}

.sportys-glyph-zoom-in {
    @extend %svg-common;
    background-position: 72.75150060024009% 46.75%;
}

.sportys-glyph-zoom-in-icon {
    width: 25px;
    height: 26px;
}

.sportys-glyph-zoom-in-dark {
    @extend %svg-common;
    background-position: 28.81152460984394% 91.2275%;
}

.sportys-glyph-zoom-in-dark-icon {
    width: 25px;
    height: 26px;
}

.sportys-glyph-zoom-out {
    @extend %svg-common;
    background-position: 31.812725090036015% 91.2275%;
}

.sportys-glyph-zoom-out-icon {
    width: 25px;
    height: 26px;
}

.sportys-glyph-zoom-out-dark {
    @extend %svg-common;
    background-position: 34.81392557022809% 91.2275%;
}

.sportys-glyph-zoom-out-dark-icon {
    width: 25px;
    height: 26px;
}

.sportys-radio-disabled {
    @extend %svg-common;
    background-position: 86.33093525179856% 99.97755610972568%;
}

.sportys-radio-disabled-icon {
    width: 24px;
    height: 24px;
}

.sportys-radio-selected {
    @extend %svg-common;
    background-position: 89.20863309352518% 99.97755610972568%;
}

.sportys-radio-selected-icon {
    width: 24px;
    height: 24px;
}

.sportys-radio-unselected {
    @extend %svg-common;
    background-position: 92.0863309352518% 99.97755610972568%;
}

.sportys-radio-unselected-icon {
    width: 24px;
    height: 24px;
}

.sportys-review-quiz {
    @extend %svg-common;
    background-position: 0 0;
}

.sportys-review-quiz-icon {
    width: 428.02px;
    height: 409.82px;
}

