@import "variables";


.card-container {
  display: flex;
  flex-wrap: wrap;
}

.card {
  box-shadow: 0px 2px rem(3) rgba(12, 24, 35, 0.17) !important;
  border-radius: rem(1);
  border: none;
  margin: rem(3);

  .card-footer-favorite {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    padding: rem(2);
    z-index: 99;
  }
  .card-course-status{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0rem 0.5rem;
    background-color: rgba(100, 100, 100, 0.75);
    color: white;
    border-radius: 1rem;
    font-size: .5rem;
    font-weight: bold;
    z-index: 99;
    font-style: italic;
  }

  &.card-course-UNPUBLISHED {
    opacity: 0.5;
    border: 2px solid gold;
  }

  &.card-course-REMOVED {
    opacity: 0.5;
    border: 2px solid darkorange;
  }

  &.card-course-HIDDEN {
    opacity: 0.5;
    border: 2px solid red;
  }

  &.card-course-PUBLISHED {

  }
}

.card-skinny {
  box-shadow: 0px 2px 10px rgba(12, 24, 35, 0.17) !important;
  margin: rem(0) rem(0) rem(6) rem(0);
  width: calc(100% / 3 - #{rem(4)});
  border-radius: rem(3);

  &:nth-child(3n+1) {
    margin: rem(0) rem(6) rem(6) rem(0);
  }

  &:nth-child(3n+3) {
    margin: rem(0) rem(0) rem(6) rem(6);
  }

  &.no-margin {
    margin: 0px;

    @media(max-width: $phone_lg) {
      margin: 20px 0px 20px 0px;
    }
  }

  &.card-complete {
    opacity: 0.66;
  }

  .card-header {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-clip: border-box;
    padding: 0px;
    margin: 0px;
    position: relative;
    padding-bottom: 56.25%;
    cursor: pointer;
    border: none !important;
    border-radius: rem(3) rem(3) 0 0;

    .lock {
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100px;
      width: 100px;
      /*background-image: url(https://dl.videos.sportys.com/onlinecourse/images/locked-sharp-corners-no-shadow@3x.png);*/
      background-image: url(https://dl.videos.sportys.com/onlinecourse/images/yellowlock.png);

      background-position: center;
      background-size: contain;
      border-radius: 0px 2px 0px 0px;

      &.lock-purchase {

      }
    }

    .lock-white {
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100px;
      width: 100px;
      /*background-image: url(https://dl.videos.sportys.com/onlinecourse/images/locked-sharp-corners-no-shadow@3x.png);*/
      background-image: url('../images/lock-white.svg');

      background-position: center;
      background-size: contain;
      border-radius: 0px 2px 0px 0px;

      &.lock-purchase {

      }
    }

    .lock-new {
      position: absolute;
      top: 0px;
      right: 0px;
      height: 60px;
      width: 60px;
      background-image: url('../images/lock-new.svg');

      background-position: center;
      background-size: contain;
      border-radius: 0px 2px 0px 0px;

      &.lock-purchase {

      }
    }

    .lock-new-white {
      position: absolute;
      top: 0px;
      right: 0px;
      height: 60px;
      width: 60px;
      background-image: url('../images/lock-new-white.svg');

      background-position: center;
      background-size: contain;
      border-radius: 0px 2px 0px 0px;

      &.lock-purchase {

      }
    }

    .card-header-text {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;

      .review-quiz {
        background: url("../../svgs/review-quiz.svg") no-repeat;
        height: 115px;
        width: 120px;
        color: $primary;
      }

    }

    .card-remove {
      position: absolute;
      top: -20px;
      right: -20px;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.85);
      opacity: 1;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(200, 200, 200, 0.5);
    }
  }

  .card-header-top-left {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 33px;
    width: 66px;
    z-index: 1;
    color: white;
    background-color: $primary_blue;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.229649) !important;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include Course_Text_Md_Bold;
  }

  .card-footer {
    height: rem(30);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(3) !important;

    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;

    background-color: inherit;
    border-radius: 0 0 rem(3) rem(3);

    .course-progress-container {
      height: 5px;
      width: 100%;
      position: absolute;
      top: 0px;
      right: 0px;

      &.course-progress-container_white {
        opacity: 0.66;
        background-color: $progress-bar-background;
      }

      .course-progress-bar {
        height: 5px;
        width: 0;
        background-color: $progress-bar-fill;
      }
    }

    .card-footer-description {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      font-size: rem(4);

      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;


      .card-footer-description-left,
      .card-footer-description-right {
        width: rem(6);

        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        line-height: rem(6);
        height: rem(6);
      }

      .card-footer-description-left {
        min-width: rem(6);
      }

      .card-footer-description-center {
        flex-grow: 1;
        line-height: rem(6);
        //overflow: hidden;
        //white-space: nowrap;
        //text-overflow: ellipsis;
      }
    }

    .card-footer-subdescription {
      font-size: rem(3);
      line-height: rem(5);
      margin: 0px 0px 0px rem(6);
    }

    .card-footer-button {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-content: center;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: rem(2) rem(3);

      .btn:first-child {
        display: block !important;
        text-align: left;
        width: 100%;
      }

      .btn:last-child {
        display: block !important;
        text-align: right;
        width: 100%;
      }
    }

    .card-footer-warning {
      width: 100%;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      bottom: 12px;
      right: 12px;
    }

    .watched {
      color: $green;
    }
  }
}

@media (max-width: $desktop_xl) {
  .card-skinny {
    box-shadow: 0px 2px 10px rgba(12, 24, 35, 0.17) !important;
    margin: rem(0) rem(0) rem(6) rem(0);
    width: calc(100% / 2 - #{rem(3)});

    &:nth-child(2n+1) {
      margin: rem(0) rem(6) rem(6) rem(0);
    }

    &:nth-child(2n+2) {
      margin: rem(0) rem(0) rem(6) rem(0);
    }
  }
}

@media (max-width: $tablet_xl) {
  .card-skinny {
    width: 100%;
    margin: rem(0) rem(0) rem(6) rem(0);

    &:nth-child(2n+1) {
      margin: rem(0) rem(0) rem(6) rem(0);
    }

    &:nth-child(2n+2) {
      margin: rem(0) rem(0) rem(6) rem(0);
    }

    &:nth-child(3n+1) {
      margin: rem(0) rem(0) rem(6) rem(0);
    }

    &:nth-child(3n+3) {
      margin: rem(0) rem(0) rem(6) rem(0);
    }
  }
}

@media (max-width: $tablet) {
  .card-skinny {
    &:nth-child(2n+1) {
      margin: rem(0) rem(0) rem(6) rem(0);
    }

    &:nth-child(2n+2) {
      margin: rem(0) rem(0) rem(6) rem(0);
    }

    .card-header {
      //height: 140px;
    }

    .card-footer {
      //height: calc(100% - 140px);

      > div:first-child {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

}
